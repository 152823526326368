interface PropsType {
	label?: string;
	defaultChecked: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Checkbox({
	label,
	defaultChecked,
	onChange,
}: PropsType) {
	return (
		<label className="container">
			{label}
			<input
				type="checkbox"
				defaultChecked={defaultChecked}
				onChange={onChange}
			/>
			<span className="checkmark"></span>
		</label>
	);
}
