import React from 'react';

export default function VerifyIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_405_68)">
				<path
					d="M9.8834 8.275H9.89173L13.9751 6.88333C13.9917 6.80833 13.9917 6.73333 13.9917 6.66667C13.9917 6.59167 13.9834 6.525 13.9584 6.45833C13.8917 6.26667 13.7667 6.05833 13.5917 5.90833V4.08333C13.5917 2.73333 13.1084 2.2 12.6084 1.89167C12.3501 1.10833 11.2751 0 9.16673 0C6.66673 0 4.7834 2.475 4.7834 4.08333C4.7834 4.75 4.7584 5.275 4.7334 5.675C4.7334 5.75833 4.72507 5.83333 4.72507 5.9C4.54173 6.06667 4.41673 6.29167 4.3584 6.50833C4.35007 6.55833 4.34173 6.60833 4.34173 6.66667C4.34173 7.31667 4.7084 8.25833 4.7584 8.36667C4.8084 8.50833 4.91673 8.625 5.0584 8.69167C5.06673 8.725 5.07507 8.775 5.07507 8.875C5.07507 9.75833 5.8334 10.5917 6.25007 10.9917C6.2084 11.9083 5.95007 12.5417 5.5834 12.7L2.31673 13.7833C1.39173 14.0917 0.700068 14.8417 0.458401 15.7917L0.0167343 17.5583C-0.0249324 17.7417 0.0167343 17.9417 0.133401 18.0917C0.250068 18.2417 0.433401 18.3333 0.625068 18.3333H9.96673C9.71673 18.0167 9.4834 17.6667 9.26673 17.2917C8.71673 16.3167 8.3334 15.1333 8.3334 13.7917V10.45C8.3334 9.46667 8.9584 8.6 9.8834 8.275Z"
					fill="#737283"
				/>
				<path
					d="M19.5758 9.855L15.2008 8.36667C15.0708 8.32167 14.9292 8.32167 14.7992 8.36667L10.4242 9.855C10.17 9.94083 10 10.1783 10 10.4467V13.795C10 17.8817 14.5617 19.8683 14.7558 19.9508C14.9125 20.0167 15.0875 20.0167 15.2442 19.9508C15.4383 19.8675 20 17.8817 20 13.795V10.4467C20 10.1783 19.83 9.94083 19.5758 9.855ZM17.7383 13.015L15.4467 15.9317C15.155 16.3042 14.6083 16.3583 14.2492 16.05L12.7908 14.8C12.4408 14.5008 12.4 13.9742 12.7008 13.625C13 13.2758 13.5267 13.2358 13.875 13.535L14.6733 14.2192L16.4275 11.9867C16.7117 11.625 17.235 11.5608 17.5975 11.8467C17.96 12.1292 18.0233 12.6533 17.7383 13.015Z"
					fill="#737283"
				/>
			</g>
			<defs>
				<clipPath id="clip0_405_68">
					<rect width={20} height={20} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
