import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';

const PrimaryButton = styled(Button)<{ borderRadius?: string }>`
	font-style: normal;
	font-weight: ${(props) => props.borderRadius || 400};
	font-size: 14px;
	line-height: 20px;
	border-radius: ${(props) => props.borderRadius || '27px'};
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	color: #181a20;
	&:hover {
		background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	}
`;

export default PrimaryButton;
