import React from 'react';
import Container from '../layouts/Container';
import { Box, Flex, Text, chakra } from '@chakra-ui/react';

const Title = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '32px',
		},
		fontWeight: 500,
	},
});

const Content = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '16px',
		},
		color: '#737283',
	},
});

export default function UserAgreementPage() {
	return (
		<Container>
			<Flex direction={'column'} gap={'20px'} py={{ base: '20px' }}>
				<Title>User Agreement</Title>

				<Box>
					<Content>2022-01-20</Content>
					<Content>11:45:35</Content>
				</Box>

				<Flex direction={'column'} gap={'20px'}>
					<Content fontWeight={500}>Chapter 1 General Provision</Content>

					<Box>
						<Content fontWeight={500}>Article 1 Purpose</Content>
						<Content>
							{`This agreement stipulates the rights and responsibilities of the
							company and its customers regarding the terms and conditions of
							use of our site and our site related services (hereinafter
							referred to as "services ") provided by ECO-CYBER, Inc(hereinafter
							referred to as "company")`}
						</Content>
					</Box>

					<Box>
						<Content fontWeight={500}>
							Article 2 (Specification, Revision and Termination of Terms)
						</Content>
						<Content>
							{`The terms of this agreement shall be posted on the company's service registration subscription site or otherwise notified to the user and shall be effective upon acceptance of these terms by the users.`}
						</Content>
						<Content>
							{`The company may amend these terms and conditions to the extent that it does not violate the relevant laws such as the "Regulation of Standardized Contracts Act".`}
						</Content>
						<Content>
							{`If the company amends the terms and conditions, it shall notify the date of application and the reason for the revision, and shall be announced along with the current terms on the initial screen of the company site, or on the popup screen or notice field prior to the effective date.`}
						</Content>
						<Content>
							The company shall notify the customers of the amendment terms in
							accordance with the preceding paragraph. If the customer has not
							clearly expressed his intention to refuse, it shall be deemed to
							have accepted the amended terms.
						</Content>
						<Content>
							{`If a customer clearly expresses that he or she does not agree to
							the application of the revised terms, the company shall not apply
							the revised terms and conditions, in which case the customer may
							terminate the user's agreement, close his or her account and shall
							not have the access to all the services on this platform.`}
						</Content>
					</Box>

					<Box>
						<Content fontWeight={500}>Article 3 (Definition of Terms)</Content>
						<Content>
							The definition of the terms used in these terms are as follows:
						</Content>
						<Content>
							customer: A person who has approved the terms and signed a
							customer agreement with the company by checking the ‘customer
							agreement’ box.
						</Content>
						<Content>
							ID: This is a combination of letters and numbers approved by the
							company for customer identification and service use.
						</Content>
						<Content>
							Password: This is a combination of letters and numbers approved by
							the company for customer identification and protection of customer
							information
						</Content>
						<Content>
							Merchant: A customer who wants to sell a crypto-currency and has
							registered or applied for the crypto-currency under the company’s
							rules.
						</Content>
						<Content>
							Buyer: A customer who wants to purchase a crypto-currency and
							registers the crypto-currency in accordance with the form offered
							online by the company.
						</Content>
						<Content>
							{`Autonomous transaction: In the process of delivering virtual
							money, it refers to the transactions that the seller and buyer
							jointly deal with without the company's participation.`}
						</Content>
					</Box>

					<Content fontWeight={500}>Chapter 2 Service application</Content>

					<Box>
						<Content fontWeight={500}>
							Article 4 (Establishment of User Contract)
						</Content>
						<Content>
							{`The user shall apply for membership by filling in customer's
							information according to the company's designated sign-up form and
							agreeing to the terms.`}
						</Content>
						<Content>
							The contract of use is signed by the customer ID. When the
							contract of use is established, the applicant is registered as a
							customer.
						</Content>
						<Content>
							Customers who use fake information are not legally protected, and
							may undertake civil and criminal liability caused by fake
							information.
						</Content>
						<Content>
							If you are under 19 years old, you may be restricted from using
							services related to crypto-currency trading provided by company.
						</Content>
						<Content>
							In accordance with the provisions of Article I, the company may
							request the verification of users’ real name through specialized
							agency.
						</Content>
						<Content>
							The company is not liable for the unfavorable condition caused by
							the refusal to verify the identity of the users.
						</Content>
					</Box>

					<Box>
						<Content fontWeight={500}>Article 5 (Service application)</Content>
						<Content>
							Apply by filling in the online customer form provided by the
							company, and agree the terms.
						</Content>
						<Content>
							All customer information filled online is considered to be real,
							and users who have not entered their real name or real information
							will not be legally protected and may be restricted from using the
							service.
						</Content>
						<Content>
							The company may suspend the service or terminate the user
							agreement if the customer’s information is fake. Any damages or
							loss caused by fake information, the users should bear all legal
							responsibilities solely.
						</Content>
						<Content>
							{`The company may provide a variety of useful information about the
							company's related services via e-mail, correspondence, telephone,
							etc.`}
						</Content>
					</Box>

					<Box>
						<Content fontWeight={500}>
							Article 6 (Use of customer Information and Approval of service
							application)
						</Content>
						<Content>Agreement to use of customer Information:</Content>
						<Content>
							{`The company shall use the customer's personal information for the
							purpose of fulfilling the user’s contract and providing the
							service under this contract.`}
						</Content>
						<Content>
							User’s information may be provided to the company and stored in
							its affiliates in order to better fulfill customer services, but
							need to notify the customer and get their approval prior to usage.
						</Content>
						<Content>
							The company is entitled to send the SMS(such as the user’s
							guidance and product information) to users, and users can stop to
							receive the SMS by unsubscribing.
						</Content>
						<Content>
							Customers can view and modify personal information at any time.
						</Content>
					</Box>

					<Box>
						<Content fontWeight={500}>
							Article 7 (Termination and Cancel of Use Contract)
						</Content>
						<Content>
							The use contract could be terminated by the customer or the
							company. The damage or loss caused by termination shall be borne
							by the customer who has terminated the use contract.
						</Content>
						<Content>
							The customer must apply online for the termination of the use
							contract if he or she wants to terminate the use contract.
						</Content>
						<Content>
							The company may terminate or restrict the use of the service and
							the use contract when the customer has following unfavorable
							behaviors:
						</Content>
						<Content>(A) If a user has following violation behavior:</Content>
						<Content>Steal someone’s service ID and password</Content>
						<Content>
							Intentionally interfere with our operation services
						</Content>
						<Content>Use fake customer information</Content>
						<Content>
							Intentionally disseminate unfavorable content which undermines
							public order and moral
						</Content>
						<Content>
							Use the services to impede national interest or social public
							interest
						</Content>
						<Content>
							Intentionally transmit large amounts of information for the
							purpose of interfering with the platform’s stable operation
						</Content>
						<Content>Intentionally send harmful information or virus.</Content>
						<Content>
							{`Copy and illegally commercialize the information out of the
							company's service`}
						</Content>
						<Content>
							Infringe the intellectual property rights of the company, other
							customers or third parties
						</Content>
						<Content>(B) For each of the following subparagraphs</Content>
						<Content>
							If the operator or administrator finds it inappropriate to use
						</Content>
						<Content>It is connected with a crime</Content>
						<Content>In violation of relevant laws</Content>
						<Content>Suspension or Restriction of Service</Content>
						<Content>
							If the company wants to restrict the use, the company shall notify
							the customer or agent of the reason by specifying the reason, date
							and time, and writing or using the telephone or homepage message
							function
						</Content>
						<Content>
							However, if the Company recognizes that it is necessary to suspend
							the use of the service urgently, it may restrict the use of the
							service without the process of the preceding paragraph
						</Content>
						<Content>
							If a customer who has been notified of suspension of use of the
							service or his / her agent has objection to suspension, he / she
							may appeal
						</Content>
						<Content>
							The Company shall immediately terminate the suspension if it is
							confirmed that the reason for suspension of use is resolved during
							the suspension period
						</Content>
						<Content>Termination of use contract</Content>
						<Content>
							If the violation activity is repeated two or more times, or if the
							reason is not corrected within 30 days, the company may terminate
							the use contract
						</Content>
						<Content>
							If the company terminates the use contract, the user’s
							registration is canceled. And the company will notify customers in
							this case and reserve their rights to call before the expiration
							date.
						</Content>
					</Box>

					<Content fontWeight={500}>Chapter 3 Customer Obligations</Content>

					<Box>
						<Content fontWeight={500}>
							{`Article 8 (Customer's Responsibility for customer ID and Password
							Management)`}
						</Content>
						<Content>
							Customers are responsible for all management of the ID and
							password. The customers are responsible for any consequences
							arising from the negligence, misuse, or misuse of the ID and
							password given to the customer.
						</Content>
						<Content>
							{`If a customer finds out that his or her ID has been misused, he
							must notify the company and follow the company's instructions.`}
						</Content>
						<Content>
							{`In the case of Paragraph 2, the company shall not be liable for
							any disadvantage caused if the customer failed to notify the
							company or the customer does not follow the company's guidance
							even if it is notified.`}
						</Content>
					</Box>

					<Box>
						<Content fontWeight={500}>
							{`Article 9 (Provision of Information)`}
						</Content>
						<Content>
							The company may provide the following service information, which
							is deemed necessary by the customer during the use of the service,
							to the customer by e-mail or letter mail If the customer does not
							want it, you can opt out
						</Content>
						<Content>{`Crypto-currency Transaction Services`}</Content>
						<Content>{`Services such as events and occasions`}</Content>
						<Content>{`Services to be determined by the Company from time to time and provided to customers`}</Content>
					</Box>
				</Flex>
			</Flex>
		</Container>
	);
}
