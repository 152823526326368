import React from 'react';

export default function MenuIcon() {
	return (
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.2774 12.8544H7.72291C7.25147 12.8544 6.86747 12.4704 6.86747 11.999C6.86747 11.5271 7.25147 11.1436 7.72291 11.1436H16.2774C16.7493 11.1436 17.1328 11.5271 17.1328 11.999C17.1328 12.4709 16.7493 12.8544 16.2774 12.8544Z"
				fill="white"
			/>
			<path
				d="M3.12095 18.8773C2.94701 18.9937 2.7379 19.0351 2.53212 18.9942C2.32634 18.9533 2.14907 18.835 2.03311 18.6615C0.70289 16.6722 0 14.3686 0 12C0 8.79446 1.248 5.78139 3.51493 3.51493C5.78139 1.248 8.79446 0 12 0C15.2055 0 18.2186 1.248 20.4851 3.51493C22.752 5.78139 24 8.79446 24 12C24 15.2055 22.752 18.2186 20.4851 20.4851C18.2186 22.752 15.2055 24 12 24C9.80578 24 7.65861 23.4021 5.79089 22.2706C5.61172 22.1622 5.48531 21.9902 5.43541 21.7868C5.3855 21.5834 5.41782 21.3728 5.52618 21.1937C5.63453 21.0145 5.80657 20.8881 6.00998 20.8382C6.21338 20.7883 6.42392 20.8206 6.60309 20.929C8.22606 21.9122 10.0924 22.4317 12 22.4317C17.7519 22.4317 22.4317 17.7519 22.4317 12C22.4317 6.24808 17.7519 1.56832 12 1.56832C6.24808 1.56832 1.56832 6.24808 1.56832 12C1.56832 14.0569 2.17996 16.0591 3.33671 17.7899C3.45315 17.9639 3.49497 18.173 3.4541 18.3788C3.41323 18.5841 3.29489 18.7613 3.12095 18.8773Z"
				fill="white"
			/>
			<path
				d="M7.72291 7.34253H16.2774C16.7493 7.34253 17.1328 7.72605 17.1328 8.19797C17.1328 8.6699 16.7493 9.05342 16.2774 9.05342H7.72291C7.25147 9.05342 6.86747 8.6699 6.86747 8.19797C6.86747 7.72605 7.25147 7.34253 7.72291 7.34253Z"
				fill="white"
			/>
			<path
				d="M16.2774 16.6574H7.72291C7.25147 16.6574 6.86747 16.2734 6.86747 15.802C6.86747 15.3305 7.25147 14.9465 7.72291 14.9465H16.2774C16.7493 14.9465 17.1328 15.3305 17.1328 15.802C17.1328 16.2734 16.7493 16.6574 16.2774 16.6574Z"
				fill="white"
			/>
		</svg>
	);
}
