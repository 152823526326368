import { useEffect, useState } from 'react';
import { basePagination } from '../../lib/pagination';
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const PageActive = styled(Text)`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	cursor: pointer;
`;

const PageNumber = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: '#ffffff';
	cursor: pointer;
`;

interface PropsType {
	handleChangePage: (page: number) => void;
	page: number;
	pagination: {
		total: number;
		pageSize: number;
	};
}

export default function Pagination({
	handleChangePage,
	page,
	pagination,
}: PropsType) {
	const [count, setCount] = useState(1);

	const pages = basePagination(page, count);

	useEffect(() => {
		setCount(Math.ceil((pagination?.total || 1) / (pagination?.pageSize || 1)));
	}, [pagination]);

	return (
		<Flex gap={'15px'}>
			{pages?.map((pageCount) => {
				if (pageCount === page)
					return <PageActive key={pageCount}>{pageCount}</PageActive>;
				return (
					<PageNumber
						key={pageCount}
						onClick={() => {
							handleChangePage(Number(pageCount));
						}}
					>
						{pageCount}
					</PageNumber>
				);
			})}
		</Flex>
	);
}
