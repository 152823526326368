import { ModalHeader, ModalContent, ModalBody } from '@chakra-ui/react';
import styled from '@emotion/styled';
import CopyIcon from '../../Icons/CopyIcon';
import TransactionHistoryDesktop from '../TransactionHistoryDesktop';
import TransactionHistoryMobile from '../TransactionHistoryMobile';

export const StyledModalHeader = styled(ModalHeader)`
	background-color: #091229;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	letter-spacing: 0.2px;
	color: #d1d4dc;
`;
export const StyledModalContent = styled(ModalContent)`
	background-color: #070c1e;
	width: fit-content;
	max-width: 1212px;
	@media screen and (max-width: 567px) {
		width: 100%;
		height: 100%;
	}
`;
export const StyledModalBody = styled(ModalBody)`
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 30px;
	max-height: 80vh;
	overflow-y: auto;
	@media screen and (max-width: 567px) {
		width: 100%;
		max-height: calc(100% - 53px);
		padding: 10px;
	}
`;
export const StepPanel = styled.div`
	padding: 20px;
	background: #091229;
	@media screen and (max-width: 567px) {
		display: none;
	}
`;
export const DepositContent = styled.div`
	display: grid;
	grid-template-columns: 40% 60%;
	@media screen and (max-width: 567px) {
		grid-template-columns: 1fr;
		gap: 30px;
	}
`;
export const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	padding-right: 74px;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
	@media screen and (max-width: 567px) {
		border-right: none;
		padding-right: 0;
	}
`;
export const RightColumn = styled.div`
	padding-left: 74px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	@media screen and (max-width: 567px) {
		padding-left: 0;
		align-items: center;
	}
`;
export const Label = styled.div`
	font-size: 16px;
	line-height: 16px;
	color: #737283;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-start;
`;
export const Row = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const Warning = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
`;
export const WarningContent = styled.div`
	font-size: 16px;
	line-height: 24px;
	text-align: justify;
	color: #fdd130;
	flex: 1;
`;
export const ChainListWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
	flex-wrap: wrap;
`;
export const ChainItem = styled.div<{ selected: boolean }>(
	(props) => `
	background: ${
		props.selected
			? ' linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%);'
			: 'rgba(255, 255, 255, 0.05)'
	};
	border-radius: 4px;
	height: 48px;
	padding: 0px 16px;
	width: 126px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: ${props.selected ? '#03040B' : '#fff'};
	${props.selected ? 'font-weight: 500;' : ''}
		@media screen and (max-width: 567px) {
		font-size: 14px;
		width: fit-content;
	}
`
);
export const DepositAddressWrapper = styled.div`
	position: relative;
	width: 100%;
`;
export const DepositAddressInput = styled.input`
	background: rgba(255, 255, 255, 0.05);
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	height: 48px;
	width: 100%;
	padding: 0 30px 0 10px;
	font-size: 16px;
	line-height: 16px;
	color: #737283;
	&:focus {
		outline: none;
	}
`;
export const StyledCopyIcon = styled(CopyIcon)`
	position: absolute;
	top: 14px;
	right: 14px;
	cursor: pointer;
`;
export const QRCodeWrapper = styled.div`
	background: #ffffff;
	border-radius: 5px;
	width: 156px;
	height: 156px;
	padding: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const Title = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
`;
export const DepositHistory = styled.div`
	display: flex;
	flex-direction: column;
	gap: 26px;
	align-items: flex-start;
	width: 100%;
`;
export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;
export const Retangle = styled.div`
	width: 100%;
	background: linear-gradient(
		100.78deg,
		rgba(255, 255, 255, 0.116) 0.27%,
		rgba(255, 255, 255, 0.024) 102.68%
	);
	height: 1px;
`;
export const StyledDepositHistoryDesktop = styled(TransactionHistoryDesktop)`
	display: table;
	@media screen and (max-width: 567px) {
		display: none;
	}
`;
export const StyledDepositHistoryMobile = styled(TransactionHistoryMobile)`
	display: none;
	@media screen and (max-width: 567px) {
		display: block;
	}
`;
