import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import {
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
} from '../Wallets/DepositModal/styles';
import { NextButton } from '../P2PAds/PostAd/PostAdModal';
import WarningIcon from '../Icons/WarningIcon';

export default function ConfirmReleaseModal({
	isOpen,
	onClose,
	onConfirmRelease,
}: {
	isOpen: boolean;
	onClose: () => void;
	onConfirmRelease: () => void;
}) {
	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>Cancel Order</StyledModalHeader>
				<ModalCloseButton />
				<StyledModalBody maxW={'548px'} w={'90vw'}>
					<Flex direction={'column'} gap={'20px'}>
						<Flex gap={'10px'}>
							<WarningIcon />
							<Text fontSize={'14px'} lineHeight={'24px'} color={'#737283'}>
								Special Tips
							</Text>
						</Flex>
						<Flex direction={'column'}>
							<Text fontSize={'14px'} lineHeight={'24px'} color={'#737283'}>
								1. Do not only check the buyer’s payment proof. Make sure to log
								into your account & verify payment is received.
							</Text>
							<Text fontSize={'14px'} lineHeight={'24px'} color={'#737283'}>
								2. If the payment is still processing, wait until you have
								received payment in your account before releasing the crypto
							</Text>
							<Text fontSize={'14px'} lineHeight={'24px'} color={'#737283'}>
								3. Do not accept payment from a third-party account. Refund the
								full amount immediately if you receive such payment to avoid
								financial losses caused by bank chargeback after you have
								released the cypto
							</Text>
						</Flex>
						<Flex
							w={'100%'}
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Box w={'100%'}>
								<NextButton onClick={onConfirmRelease}>
									Confirm Release
								</NextButton>
							</Box>
						</Flex>
					</Flex>
				</StyledModalBody>
			</StyledModalContent>
		</Modal>
	);
}
