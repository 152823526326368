import DepositFunds from '../DepositFunds';
import FundList from '../FundList/FundList';
import SpotBanner from './SpotBanner';

const SpotAccount = () => {
	return (
		<>
			<SpotBanner />
			<DepositFunds />
			<FundList />
		</>
	);
};

export default SpotAccount;
