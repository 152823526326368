import React from 'react';

export default function ArrowStepIcon() {
	return (
		<svg
			width={22}
			height={4}
			viewBox="0 0 22 4"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.4 2C0.4 2.88366 1.11634 3.6 2 3.6C2.88366 3.6 3.6 2.88366 3.6 2C3.6 1.11634 2.88366 0.4 2 0.4C1.11634 0.4 0.4 1.11634 0.4 2ZM22 2L19 0.267949V3.73205L22 2ZM2 2.3H19.3V1.7H2V2.3Z"
				fill="url(#paint0_linear_59_659)"
				fillOpacity="0.6"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_59_659"
					x1={2}
					y1="2.01714"
					x2="3.57194"
					y2="8.00531"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" stopOpacity="0.58" />
					<stop offset={1} stopColor="white" stopOpacity="0.12" />
				</linearGradient>
			</defs>
		</svg>
	);
}
