import React from 'react';

export default function AccountListIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_405_2)">
				<path
					d="M3.12618 4.56329H16.8738C17.3408 4.56329 17.7197 4.19571 17.7197 3.7418C17.7197 3.2879 17.3412 2.92032 16.8738 2.92032H3.12618C2.65918 2.92032 2.28027 3.2879 2.28027 3.7418C2.28027 4.19571 2.65918 4.56329 3.12618 4.56329Z"
					fill="white"
				/>
				<path
					d="M4.41475 1.64258H15.5845C16.0515 1.64258 16.4304 1.275 16.4304 0.821094C16.4304 0.367187 16.0519 0 15.5849 0H4.41475C3.94775 0 3.56885 0.367578 3.56885 0.821484C3.56885 1.27539 3.94775 1.64258 4.41475 1.64258Z"
					fill="white"
				/>
				<path
					d="M17.0595 5.84102H2.94098C1.16631 5.84102 -0.208536 7.36055 0.0267721 9.07383L1.18682 17.5223C1.3803 18.9348 2.63326 20 4.10103 20H15.8998C17.3672 20 18.6202 18.9348 18.814 17.5223L19.9741 9.07383C20.209 7.36211 18.8358 5.84102 17.0595 5.84102ZM10.0002 7.85938C11.2709 7.85938 12.3006 8.85977 12.3006 10.0938C12.3006 11.3273 11.2705 12.3273 9.99982 12.3273C8.72956 12.3273 7.69984 11.327 7.69984 10.093C7.70024 8.85938 8.72996 7.85938 10.0002 7.85938ZM13.0721 17.1801C11.1876 18.3039 8.81282 18.3039 6.92794 17.1801C5.95051 16.5953 5.76025 15.2887 6.53657 14.4672C7.42591 13.5234 8.6829 12.9867 9.99982 12.9883C11.3167 12.9867 12.5737 13.5234 13.4631 14.4672C14.2398 15.2887 14.0495 16.5953 13.0721 17.1801Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_405_2">
					<rect width={20} height={20} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
