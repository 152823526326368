export default function DropdownIcon() {
	return (
		<svg
			width={16}
			height={16}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.865 5C11.7128 5 12.176 5.98886 11.6332 6.64018L8.76822 10.0781C8.36843 10.5579 7.63157 10.5579 7.23178 10.0781L4.36682 6.64018C3.82405 5.98886 4.2872 5 5.13504 5H10.865Z"
				fill="#737283"
			/>
		</svg>
	);
}
