import { Box, chakra } from '@chakra-ui/react';

const LineBox = chakra(Box, {
	baseStyle: {
		h: '1px',
		bg: 'linear-gradient(100.78deg, rgba(255, 255, 255, 0.116) 0.27%, rgba(255, 255, 255, 0.024) 102.68%)',
	},
});

export default LineBox;
