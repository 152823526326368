// theme.ts

// 1. import `extendTheme` function
import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

// 2. Add your color mode config
const config: ThemeConfig = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
};

const baseStyle = {
	indicator: {
		// borderRadius: 0,
		color: '#03A66D',
		background: 'black',
	},
	separator: {
		width: 0,
		borderRight: '1px dashed #737283',
		'[data-status=complete]': {
			background: '#03A66D',
		},
	},
};

const sizes = {
	lg: {
		// select the title part
		title: {
			// change the font size to lg
			fontSize: 'lg',
		},
	},
};

const stepperTheme = {
	baseStyle,
	sizes,
};

// 3. extend the theme
const theme = extendTheme({
	config,
	components: {
		Stepper: stepperTheme,
	},
	styles: {
		global: (props: StyleFunctionProps) => ({
			body: {
				bg: mode('white', 'black')(props),
			},
		}),
	},
});

export default theme;
