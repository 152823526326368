export default function CopyIcon(props: any) {
	return (
		<svg
			{...props}
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.4567 1.6665H7.87683C6.29075 1.6665 5.00008 2.95717 5.00008 4.54325V4.99984H4.5435C2.95741 4.99984 1.66675 6.2905 1.66675 7.87659V15.4563C1.66675 17.0425 2.95741 18.3332 4.5435 18.3332H12.1232C13.5803 18.3332 14.7751 17.24 14.9619 15.8332H15.4566C17.0427 15.8332 18.3334 14.5425 18.3334 12.9564V4.54325C18.3334 2.95717 17.0427 1.6665 15.4567 1.6665ZM16.6667 12.9564C16.6667 13.6237 16.1239 14.1665 15.4567 14.1665H15.0001V7.87659C15.0001 6.2905 13.7094 4.99984 12.1233 4.99984H6.66675V4.54325C6.66675 3.876 7.20958 3.33317 7.87683 3.33317H15.4566C16.1239 3.33317 16.6667 3.876 16.6667 4.54325V12.9564Z"
				fill="#737283"
			/>
		</svg>
	);
}
