import React from 'react';

const MinusIcon = ({
	width = 26,
	height = 26,
}: {
	width?: number;
	height?: number;
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9844 14.0156H9.00504C8.44391 14.0156 7.98941 13.5611 7.98941 13C7.98941 12.4389 8.44391 11.9844 9.00504 11.9844H11.9844V14.0156ZM10.5193 11.9844H16.995C17.5561 11.9844 18.0106 12.4389 18.0106 13C18.0106 13.5611 17.5561 14.0156 16.995 14.0156H10.5193V11.9844ZM26 7.53441V18.4656C26 20.3648 25.2606 22.1498 23.9175 23.4924C23.7037 23.6432 21.938 26 18.4651 26H7.53441C4.06199 26 2.29836 23.6453 2.08203 23.4924C0.739375 22.1498 0 20.3643 0 18.4656V7.53441C0 5.63519 0.739375 3.85023 2.08254 2.50707C2.29633 2.35625 4.06199 0 7.53441 0H18.4656C21.938 0 23.7016 2.35473 23.918 2.50758C25.2606 3.85023 26 5.6357 26 7.53441ZM23.9688 7.53441C23.9688 6.17805 23.4406 4.90293 22.4814 3.94367C22.2589 3.78371 21.0234 2.03125 18.4656 2.03125H7.53441C4.99027 2.03125 3.73344 3.7893 3.51863 3.94367C2.55938 4.90293 2.03125 6.17805 2.03125 7.53441V18.4656C2.03125 19.822 2.55938 21.0971 3.51863 22.0563C3.74105 22.2163 4.97656 23.9688 7.53441 23.9688H18.4656C21.0102 23.9688 22.2666 22.2107 22.4814 22.0563C23.4406 21.0971 23.9688 19.822 23.9688 18.4656V7.53441Z"
				fill="white"
			/>
		</svg>
	);
};

export default MinusIcon;
