import {
	Box,
	Button,
	Input,
	InputGroup,
	InputRightElement,
	Select,
	Text,
} from '@chakra-ui/react';
import React from 'react';

const VerificationPhone = () => {
	return (
		<Box>
			<Text
				fontSize={'20px'}
				fontWeight={'500'}
				lineHeight={'48px'}
				mb={'14px'}
			>
				Link the mobile phone
			</Text>
			<Box mx="auto" maxW={'458px'}>
				<form>
					<Text mb={'11px'} color={'#737283'}>
						Nationality
					</Text>
					<Select
						placeholder=""
						bg="rgba(255, 255, 255, 0.05)"
						color="#737283"
						mb={'20px'}
					></Select>

					<Text mb={'11px'} color={'#737283'}>
						Mobile Number
					</Text>
					<Input
						bg="rgba(255, 255, 255, 0.05)"
						color="#737283"
						mb={'20px'}
						placeholder="Enter your mobile number..."
					/>

					<Text mb={'11px'} color={'#737283'}>
						Phone Verification Code
					</Text>
					<InputGroup mb={'20px'}>
						<Input
							pr="4.5rem"
							placeholder="Enter your Phone Verification Code..."
						/>
						<InputRightElement width="4.5rem">
							<Button h="1.75rem" size="sm" color={'yellow'}>
								Send
							</Button>
						</InputRightElement>
					</InputGroup>

					<Text mb={'11px'} color={'#737283'}>
						Google Authentication Code
					</Text>
					<Input
						bg="rgba(255, 255, 255, 0.05)"
						color="#737283"
						mb={'20px'}
						placeholder="Enter your Google Authentication Code..."
					/>

					<Button
						w={'100%'}
						py={'14px'}
						color={'black'}
						bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
						borderRadius={'36px'}
					>
						Connect
					</Button>
				</form>
			</Box>
		</Box>
	);
};

export default VerificationPhone;
