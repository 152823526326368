import React from 'react';

const ChatIcon = () => {
	return (
		<svg
			width={30}
			height={32}
			viewBox="0 0 30 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M26.7957 15.4546C26.7957 8.90724 21.4638 3.59961 14.8866 3.59961C8.30941 3.59961 2.97754 8.90724 2.97754 15.4546C2.97754 22.0019 8.30941 27.3096 14.8866 27.3096C16.2671 27.3096 17.5923 27.075 18.8252 26.645L21.0235 27.7066C21.6875 28.0273 22.4584 27.5435 22.4584 26.8062V24.6053C25.1072 22.4309 26.7957 19.139 26.7957 15.4546Z"
				fill="url(#paint0_linear_1223_3015)"
			/>
			<path
				d="M14.8866 3.59961C14.5115 3.59961 14.1407 3.61774 13.7745 3.65148C19.8302 4.20982 24.5716 9.28063 24.5716 15.4546C24.5716 21.5263 19.7026 26.6848 13.7577 27.2562C14.1293 27.291 14.5058 27.3095 14.8866 27.3095C16.267 27.3095 17.5922 27.0749 18.8251 26.6449L21.0235 27.7066C21.6875 28.0273 22.4583 27.5435 22.4583 26.8061V24.6052C25.1071 22.4309 26.7956 19.139 26.7956 15.4546C26.7956 8.90725 21.4637 3.59961 14.8866 3.59961Z"
				fill="black"
				fillOpacity="0.05"
			/>
			<path
				d="M21.6144 12.4573C21.4875 12.2807 21.255 12.2279 21.0688 12.3333L16.6358 14.8441L13.878 12.3873C13.7089 12.2367 13.4567 12.2467 13.2992 12.4104L8.18424 17.7243C8.01829 17.8977 8.01909 18.1781 8.18608 18.3505C8.31959 18.4884 8.52431 18.519 8.68978 18.4257L13.1227 15.9149L15.8831 18.3726C16.0522 18.5232 16.3044 18.5132 16.4619 18.3495L21.5769 13.0356C21.7259 12.8793 21.7419 12.633 21.6144 12.4573Z"
				fill="#FAFAFA"
			/>
			<circle
				cx="23.5597"
				cy="6.70035"
				r="4.34"
				fill="url(#paint1_linear_1223_3015)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_1223_3015"
					x1="3.44813"
					y1="4.03416"
					x2="18.1633"
					y2="20.2681"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CB7108" />
					<stop offset={1} stopColor="#EA991C" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_1223_3015"
					x1="19.2197"
					y1="11.0404"
					x2="27.8997"
					y2="11.0404"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default ChatIcon;
