import React from 'react';

const ArrowRightIcon = () => {
	return (
		<svg
			width={8}
			height={12}
			viewBox="0 0 8 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.39 5.657L0.5 9.547L2.268 11.314L7.925 5.657L6.157 3.889L2.268 0L0.5 1.768L4.39 5.658V5.657Z"
				fill="url(#paint0_linear_452_9635)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_452_9635"
					x1="0.5"
					y1="11.314"
					x2="7.925"
					y2="11.314"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default ArrowRightIcon;
