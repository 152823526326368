import { Button, HTMLChakraProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import TextGradient from './TextGradient';

const ButtonWrapper = styled(Button)<any>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	border-radius: 5px;
	height: 38px;
	min-width: 130px;
	width: fit-content;
	border: ${(props) =>
		props.isGradient ? '1px solid #fcd535' : '1px solid #737283'};
	background: transparent;
	@media screen and (max-width: 567px) {
		min-width: fit-content;
	}
`;

const TextButton = styled.span`
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: #737283;
`;

interface OutlineButtonProps extends HTMLChakraProps<'button'> {
	children: React.ReactNode;
	textProps?: React.HTMLAttributes<HTMLSpanElement>;
	isGradient?: boolean;
}
const OutlineButton: React.FC<OutlineButtonProps> = ({
	children,
	textProps,
	isGradient = true,
	...props
}: OutlineButtonProps) => {
	return (
		<ButtonWrapper {...props} isGradient={isGradient}>
			{isGradient ? (
				<TextGradient {...textProps}>{children}</TextGradient>
			) : (
				<TextButton {...textProps}>{children}</TextButton>
			)}
		</ButtonWrapper>
	);
};
export default OutlineButton;
