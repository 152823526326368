import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';
import React from 'react';

export default function ConfirmDeleteDialog({
	isOpen,
	onClose,
	onSubmit,
	title,
	description,
}: {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	title: string;
	description: string;
}) {
	const cancelRef = React.useRef(null);

	return (
		<>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				isOpen={isOpen}
				isCentered
			>
				<AlertDialogOverlay />

				<AlertDialogContent>
					<AlertDialogHeader bg={'#091229'} fontSize={'18px'}>
						{title}
					</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody bg={'#070C1E'}>{description}</AlertDialogBody>
					<AlertDialogFooter bg={'#070C1E'}>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
						</Button>
						<Button colorScheme="red" ml={3} onClick={onSubmit}>
							Delete
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
}
