export default function CheckedIcon(props: any) {
	return (
		<svg
			{...props}
			width={21}
			height={14}
			viewBox="0 0 21 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.6024 0.99153C19.0397 0.398223 18.1016 0.372092 17.5074 0.936198L8.04654 9.90855L3.53398 5.27541C2.96245 4.68902 2.02537 4.67617 1.43849 5.24778C0.852108 5.81881 0.839756 6.75689 1.41079 7.34327L6.94346 13.0237C7.23341 13.3215 7.61873 13.4717 8.00506 13.4717C8.37161 13.4717 8.73816 13.3363 9.02518 13.0651L19.5471 3.0866C20.1409 2.52342 20.166 1.58534 19.6024 0.99153Z"
				fill="white"
			/>
		</svg>
	);
}
