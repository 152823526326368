export default function TransferIcon(props: any) {
	return (
		<svg
			{...props}
			width={16}
			height={16}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_544_4528)">
				<path
					d="M15.0312 9.87502C14.7725 9.87502 14.5625 9.66502 14.5625 9.40627C14.5625 7.59689 13.0906 6.12502 11.2812 6.12502H6.75V8.15627C6.75 8.56877 6.25375 8.77752 5.9575 8.49502L2.52 5.21377C2.4275 5.12564 2.375 5.00314 2.375 4.87502C2.375 4.74689 2.4275 4.62439 2.52 4.53627L5.9575 1.25502C6.255 0.971268 6.75 1.18252 6.75 1.59377V3.62502H10.6562C13.3269 3.62502 15.5 5.79814 15.5 8.46877V9.40627C15.5 9.66502 15.29 9.87502 15.0312 9.87502Z"
					fill="url(#paint0_linear_544_4528)"
				/>
				<path
					d="M9.25 14.4062V12.375H5.34375C2.67313 12.375 0.5 10.2019 0.5 7.53125V6.59375C0.5 6.335 0.71 6.125 0.96875 6.125C1.2275 6.125 1.4375 6.335 1.4375 6.59375C1.4375 8.40313 2.90937 9.875 4.71875 9.875H9.25V7.84375C9.25 7.43313 9.74438 7.22 10.0425 7.505L13.48 10.7863C13.5725 10.8744 13.625 10.9969 13.625 11.125C13.625 11.2531 13.5725 11.3756 13.48 11.4637L10.0425 14.745C9.74438 15.0287 9.25 14.8175 9.25 14.4062Z"
					fill="url(#paint1_linear_544_4528)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_544_4528"
					x1="2.375"
					y1="9.87502"
					x2="15.5"
					y2="9.87502"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_544_4528"
					x1="0.5"
					y1="14.875"
					x2="13.625"
					y2="14.875"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
				<clipPath id="clip0_544_4528">
					<rect
						width={15}
						height={15}
						fill="white"
						transform="translate(0.5 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
