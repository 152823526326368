import React from 'react';

export default function AdManagementIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19 8.89753C19 7.49088 17.9091 6.2677 16.4091 5.9619V2.78164C16.4091 2.2312 15.7955 1.86425 15.25 2.04773L8.02273 4.31061L7 4.43293V12.5671L8.02273 12.6894L15.25 14.9523C15.7955 15.1357 16.4091 14.7688 16.4091 14.2184V11.8332C17.9091 11.5274 19 10.3042 19 8.89753ZM16.4091 10.243V7.6132C16.8864 7.85783 17.2955 8.3471 17.2955 8.95869C17.2955 9.44797 16.9545 9.93724 16.4091 10.243Z"
				fill="#737283"
			/>
			<path
				d="M7.59769 14.6H7.53471L3 14L3.62982 16.7333C3.81877 17.4667 4.44859 18 5.14139 18H7.2198C7.72366 18 8.10155 17.5333 7.97559 17L7.59769 14.6Z"
				fill="#737283"
			/>
			<path
				d="M6 5L3.2 5.31496C1.93333 5.44094 1 6.44882 1 7.64567V10.3543C1 11.5512 1.93333 12.5591 3.2 12.685L6 13V5Z"
				fill="#737283"
			/>
		</svg>
	);
}
