import { useEffect, useState } from 'react';

type WindowDimentions = {
	width: number | undefined;
	height: number | undefined;
};

type MediaQuary = {
	isExtraSmall: boolean;
	isSmall: boolean;
	isMedium: boolean;
	isLarge: boolean;
};

const match = {
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	xxl: 1536,
};

const useMediaQuery = (): MediaQuary => {
	const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		function handleResize(): void {
			setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		handleResize();
		window.addEventListener('resize', handleResize);
		return (): void => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount

	if (windowDimensions.width && windowDimensions.height) {
		return {
			isExtraSmall: windowDimensions.width <= match.sm,
			isSmall:
				windowDimensions.width >= match.sm && windowDimensions.width < match.md,
			isMedium:
				windowDimensions.width >= match.md && windowDimensions.width < match.lg,
			isLarge: windowDimensions.width >= match.lg,
		};
	}

	return {
		isExtraSmall: false,
		isSmall: false,
		isMedium: false,
		isLarge: false,
	};
};

export default useMediaQuery;
