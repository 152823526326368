import React from 'react';

export default function WalletIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.75 15V16.5625C18.75 17.4244 18.0487 18.125 17.1875 18.125H3.75C2.37125 18.125 1.25 17.0037 1.25 15.625C1.25 15.625 1.25 5.00938 1.25 5C1.25 3.62125 2.37125 2.5 3.75 2.5H15.3125C15.8306 2.5 16.25 2.92 16.25 3.4375C16.25 3.955 15.8306 4.375 15.3125 4.375H3.75C3.40563 4.375 3.125 4.655 3.125 5C3.125 5.345 3.40563 5.625 3.75 5.625H17.1875C18.0487 5.625 18.75 6.32562 18.75 7.1875V8.75H15.625C13.9019 8.75 12.5 10.1519 12.5 11.875C12.5 13.5981 13.9019 15 15.625 15H18.75Z"
				fill="white"
			/>
			<path
				d="M18.75 10V13.75H15.625C14.5894 13.75 13.75 12.9106 13.75 11.875C13.75 10.8394 14.5894 10 15.625 10H18.75Z"
				fill="white"
			/>
		</svg>
	);
}
