import React from 'react';

export default function TradeIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.00628 9.82179L7.53441 9.29367C7.61545 9.21396 7.67293 9.11344 7.7005 9.00316C7.72807 8.89288 7.72466 8.77713 7.69066 8.66867C7.65805 8.56014 7.59668 8.46245 7.51305 8.38598C7.42943 8.3095 7.32666 8.25709 7.21566 8.23429L2.69691 7.32804C2.59559 7.30874 2.49109 7.31457 2.39254 7.34501C2.294 7.37546 2.20442 7.42959 2.13165 7.50267C2.05887 7.57576 2.00512 7.66556 1.97509 7.76423C1.94506 7.86291 1.93968 7.96743 1.95941 8.06867L2.86566 12.5812C2.88795 12.6926 2.94003 12.7958 3.01635 12.88C3.09267 12.9641 3.19037 13.026 3.29906 13.059C3.40775 13.092 3.52336 13.095 3.63359 13.0675C3.74382 13.04 3.84454 12.9832 3.92503 12.903L4.45003 12.378L10.5438 18.4687C10.6325 18.5578 10.7379 18.6285 10.854 18.6768C10.9701 18.7251 11.0946 18.7499 11.2203 18.7499C11.3461 18.7499 11.4706 18.7251 11.5867 18.6768C11.7028 18.6285 11.8082 18.5578 11.8969 18.4687L13.1 17.2655C13.1892 17.1774 13.2597 17.0722 13.3075 16.9562C13.3553 16.8403 13.3793 16.7159 13.3782 16.5905C13.379 16.4647 13.3549 16.34 13.3071 16.2236C13.2594 16.1072 13.189 16.0014 13.1 15.9124L7.00628 9.82179Z"
				fill="#737283"
			/>
			<path
				d="M18.0409 11.9312L17.1346 7.41866C17.1123 7.30727 17.0602 7.20401 16.9839 7.11987C16.9076 7.03573 16.8099 6.97386 16.7012 6.94083C16.5925 6.90781 16.4769 6.90487 16.3667 6.93233C16.2564 6.95979 16.1557 7.01663 16.0752 7.09678L15.5502 7.62178L9.45649 1.53116C9.27446 1.35668 9.03206 1.25928 8.77992 1.25928C8.52778 1.25928 8.28539 1.35668 8.10336 1.53116L6.90023 2.73428C6.81108 2.82247 6.74052 2.92764 6.69275 3.04359C6.64497 3.15953 6.62095 3.28389 6.62211 3.40928C6.62124 3.5351 6.64537 3.65984 6.69312 3.77626C6.74086 3.89267 6.81127 3.99843 6.90023 4.08741L12.994 10.178L12.4659 10.703C12.3862 10.7843 12.3297 10.8854 12.3022 10.9958C12.2748 11.1063 12.2773 11.222 12.3096 11.3312C12.3429 11.4399 12.4048 11.5376 12.489 11.614C12.5731 11.6905 12.6763 11.7428 12.7877 11.7655L17.3034 12.6718C17.3427 12.6779 17.3823 12.681 17.4221 12.6812C17.5051 12.6819 17.5875 12.6659 17.6642 12.6342C17.741 12.6026 17.8106 12.5558 17.869 12.4968C17.9418 12.4238 17.9955 12.334 18.0255 12.2354C18.0555 12.1368 18.0607 12.0323 18.0409 11.9312Z"
				fill="#737283"
			/>
		</svg>
	);
}
