import React from 'react';
import Container from '../layouts/Container';
import { Box, Flex, Text, chakra } from '@chakra-ui/react';

const Title = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '32px',
		},
		fontWeight: 500,
	},
});

const Content = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '16px',
		},
		color: '#737283',
	},
});

export default function PrivacyPolicyPage() {
	return (
		<Container>
			<Flex direction={'column'} gap={'20px'} py={{ base: '20px' }}>
				<Title>Privacy Policy</Title>

				<Box>
					<Content>2022-01-20</Content>
					<Content>11:45:35</Content>
				</Box>

				<Flex direction={'column'} gap={'20px'}>
					<Content>Privacy Statement</Content>

					<Content>
						{`In order to protect the personal information of the customer and to
						prevent the damage caused by leakage of personal information,our
						site takes the customer's personal information very seriously, and
						we comply with the related laws and regulations that information and
						communication service, such as the Act on Promotion of Information
						Network Usage and Information Protection, and the Personal
						Information Protection Act Our privacy policy is subject to change
						due to changes in laws or guidelines related to the protection of
						personal information or changes in our policies, so please check
						with us whenever you visit our site`}
					</Content>

					<Flex direction={'column'} gap={'20px'}>
						<Content>General Rules</Content>
						<Content>
							{`our site is committed to protecting the privacy of users' personal
							information and is committed to protecting the personal
							information that the user provides to the company while using the
							company's services. Accordingly, the Company complies with the
							personal information protection regulations of the related laws
							and regulations and the personal information protection guidelines
							established by the Ministry of Information and Communication,
							which should be complied with by the providers of information and
							communication services, such as the Promotion of Information
							Network Usage and Information Protection Act`}
						</Content>
						<Content>
							The Company will disclose through its Privacy Policy how and in
							what manner personal information provided by users is being used
							and what measures are being taken to protect personal information
						</Content>
						<Content>
							The company discloses its privacy policy at the front page of the
							homepage so that users can easily view it at any time
						</Content>
						<Content>
							{`The privacy policy of the company may change from time to time due
							to changes in the laws and guidelines of the government or changes
							in the company\'s internal policy Users are requested to check the
							privacy policy on the first page of the website from time to time.`}
						</Content>

						<Flex direction={'column'} gap={'20px'}>
							<Content>1. Agreement to collect personal information</Content>
							<Content>
								The company discloses its privacy policy at the front page of
								the homepage so that users can easily view it at any time
							</Content>
						</Flex>

						<Flex direction={'column'} gap={'20px'}>
							<Content>
								2. Purpose of usage and collection of personal information
							</Content>
							<Content>
								Personal information means information about an individual who
								is alive and includes information that can identify the
								individual by the name, date of birth, etc included in the
								information (even if the information alone can not identify a
								particular individual, including those that can be easily
								identified and combined with).
							</Content>
							<Content>
								Personal information collected about individual customers and
								the purpose of collection and use are as follows.
							</Content>
							<Box>
								<Content>
									- Name, ID, Password, Passport number (for foreigners only),
									Email address: Confirm your identity and confirm your
									customership
								</Content>
								<Content>
									- Your bank account, a copy of your photo ID (cover up the
									details other than your birth date), mobile number: ID
									verification for payment and withdrawal
								</Content>
								<Content>
									- Optional: Materials to provide personalized services
								</Content>
								<Content>
									- Preventing fraudulent use of fraudulent customers and
									preventing unauthorized use
								</Content>
							</Box>
						</Flex>

						<Flex direction={'column'} gap={'20px'}>
							<Content>
								3. Preventing fraudulent use of fraudulent customers and
								preventing unauthorized use
							</Content>
							<Content>
								When a user joins a customer to use a customership service, the
								company receives online information that is essential for
								providing the service The essential information you receive when
								you sign up is your name, email address, and so on Also, in
								order to provide high quality service, user can input optional
								phone number and bank account number And we can also request
								personal information for statistical analysis or offer of prizes
								during surveys or events at the exchange.
							</Content>
							<Content>
								We do not collect sensitive personal information (such as race
								and ethnicity, ideology, creed, hometown and homeland, political
								orientation and criminal record, health status, and sex life)
								that may be of concern to your basic human rights violations If
								we collect inevitably, we will ask for your prior consent And,
								in any case, the information you enter will not be used for any
								other purpose other than for the purpose previously provided to
								you, and will not be leaked out.
							</Content>
						</Flex>

						<Flex direction={'column'} gap={'20px'}>
							<Content>
								4. Retention and use period of personal information collected
							</Content>
							<Content>
								When a company collects personal information of a user, the
								period of retention is until customership termination (including
								withdrawal application, withdrawal of directorship) In addition,
								at the time of termination, the Company destroys the personal
								information of the user and instructs the third party to destroy
								the personal information provided to the third party However, if
								there is a necessity to preserve it in accordance with laws and
								regulations such as commercial law, we have transaction history
								and minimum basic information for the period of preservation
								prescribed by laws and ordinances In addition, we will keep your
								personal information for the duration of your promised period if
								you notify the user in advance of the period of retention, if
								the period of retention has not elapsed and if the individual
								consents of the user.
							</Content>
							<Box>
								<Content>
									- Records on contract or withdrawal of subscription: 5 years
								</Content>
								<Content>- Record of payment and goods supply: 5 years</Content>
								<Content>
									- Records of consumer complaints or disputes: 3 years
								</Content>
							</Box>
							<Content>
								In the event that a user requests access to transaction
								information, etc, which is held with the consent of the user,
								the Company shall take measures so that it can be read and
								confirmed without delay.
							</Content>
						</Flex>

						<Flex direction={'column'} gap={'20px'}>
							<Content>
								5.Procedures and methods of personal information destruction
							</Content>
							<Content>
								{`In principle, the personal information of the user is destroyed
								without delay when the purpose of collecting and using the
								personal information is achieved And the procedure and method of
								destroying company's personal information is as follows
								Destruction procedure.`}
							</Content>
							<Content>
								- The information entered by the user for customership purposes
								is transferred to a separate DB after the purpose has been
								achieved (in the case of paper, separate documents) According to
								internal policies and other relevant laws and regulations, it
								will be destroyed after a certain period of storage.
							</Content>
							<Content>Destruction Methods</Content>
							<Box>
								<Content>
									- Personal information printed on paper is crushed or
									destroyed by crushing
								</Content>
								<Content>
									- Personal information stored in the form of electronic files
									is deleted using a technical method that can not reproduce the
									record.
								</Content>
							</Box>
						</Flex>

						<Flex direction={'column'} gap={'20px'}>
							<Content>
								{`6.Regarding the user's personal information management (reading,
								correcting, deleting, etc)`}
							</Content>
							<Content>
								{`Users can log in to the our site website at any time and read or
								correct the user's personal information by changing the
								customer's information In addition, we will correct if you
								request by e-mail or in writing to the person in charge of
								personal information management on the company's homepage
								However, the customer ID and the name can not be corrected.`}
							</Content>
							<Content>
								Users may withdraw your consent to withdraw or terminate your
								consent to the collection and use of your personal information
								and to withdraw your consent to third parties through e-mail,
								telephone, fax or other means In this case, the user must
								provide his / her ID and e-mail address for proof of identity.
							</Content>
						</Flex>

						<Flex direction={'column'} gap={'20px'}>
							<Content>
								7.Matters concerning the operation of cookies(cookies)
							</Content>
							<Content>
								{`In order to provide customized services tailored to the customers, the company operates 'cookies' (cookies) to store and retrieve information from time to time The Company identifies your computer with respect to cookie management, but does not personally identify you.`}
							</Content>
							<Content>
								{`The user has a choice of 'cookies' And the user can accept all cookies by selecting [Tools]> [Internet Options]> [Security]> [User Defined Level] in the web browser, check each time a cookie is saved, or refuse to store all cookies However, if you refuse to store all cookies, you will not be able to use the services provided by the company through cookies.`}
							</Content>
						</Flex>

						<Flex direction={'column'} gap={'20px'}>
							<Content>
								8.Measures to Ensure the Safety of Personal Information
							</Content>
							<Content>
								The Company, pursuant to Article 29 of the Personal Data
								Protection Act, has the following technical, administrative and
								physical measures to ensure safety:
							</Content>
							<Content>
								Minimization and training of personal information handling staff
								<br />
								We designate employees who handle personal information and limit
								them to the person in charge, and implement measures to manage
								personal information.
							</Content>
							<Content>
								Regular self-audit conducted
								<br />
								We conduct our own audits on a regular basis (quarterly basis)
								to ensure the safety of handling personal information.
							</Content>
							<Content>
								Establishment and implementation of internal management plan
								<br />
								We have established and implemented an internal management plan
								for the safe handling of personal information.
							</Content>
							<Content>
								Encryption of personal information
								<br />
								The personal information of the user is encrypted and stored and
								managed so that only the user can know it, and the important
								data is using separate security functions such as encrypting the
								file and transmission data or using the file lock function.
							</Content>
							<Content>
								Technical measures against hacking
								<br />
								The company installs security programs, periodically updates and
								checks, installs systems from outside controlled areas, and
								technically and physically monitors and blocks them to prevent
								leakage and damage of personal information caused by hacking or
								computer viruses.
							</Content>
							<Content>
								Restrict access to personal information
								<br />
								We take necessary measures to control access to personal
								information through granting, modifying, and deleting access to
								the database system that handles personal information, and we
								control unauthorized access from outside by using an intrusion
								prevention system.
							</Content>
							<Content>
								Keeping connection logs and preventing forgery
								<br />
								We keep and manage the records of access to the personal
								information processing system for at least six months, and we
								use security features to prevent forgery, theft and loss of
								access records.
							</Content>
							<Content>
								Using a lock for document security
								<br />
								We keep the documents containing the personal information and
								the auxiliary storage medium in a safe place with a lock.
							</Content>
							<Content>
								Access control to unauthorized persons
								<br />
								We keep separate physical storage places for personal
								information and set up access control procedures for them.
							</Content>
						</Flex>

						<Flex direction={'column'} gap={'20px'}>
							<Content>
								9 Personal Information Management Officer and Person in Charge
							</Content>
							<Content>
								The company is doing its best to make sure that you use the good
								information securely In case of any incidents that are against
								the notice to you in protecting personal information, the person
								in charge of personal information management is responsible.
							</Content>
							<Content>
								The responsibility for maintaining the security of the password
								of the user ID is related to the user himself / herself The
								company does not ask the user directly about the password in any
								way, so please be careful not to let the password leak to the
								other person Especially if you are online in a public place.
							</Content>
							<Content>
								{`Despite the company's technical remedies, we are not liable for
								damage to information due to unforeseen accidents caused by
								basic network dangers such as hacking.`}
							</Content>
							<Content>
								The company has designated the personal information
								administrator and person in charge of collecting opinions and
								complaints about personal information, and the contact
								information is as follows [Personal information administrator].
							</Content>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Container>
	);
}
