import React from 'react';
import AssetBanner from '../../components/Wallets/AssetOverview/AssetBanner';
import AssetAccount from '../../components/Wallets/AssetOverview/AssetAccount';

export default function AssetOverview() {
	return (
		<div>
			<AssetBanner />
			<AssetAccount />
		</div>
	);
}
