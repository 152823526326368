import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import {
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
} from '../Wallets/DepositModal/styles';
import { NextButton, PreviousButton } from '../P2PAds/PostAd/PostAdModal';

export default function CancelPaymentModal({
	isOpen,
	onClose,
	onCancelPayment,
}: {
	isOpen: boolean;
	onClose: () => void;
	onCancelPayment: () => void;
}) {
	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>Cancel Order</StyledModalHeader>
				<ModalCloseButton />
				<StyledModalBody maxW={'548px'} w={'90vw'}>
					<Flex direction={'column'} gap={'30px'}>
						<Flex direction={'column'}>
							<Text fontSize={'14px'} lineHeight={'24px'} color={'#737283'}>
								1. Please do not cancel the order if you have already paid the
								seller.
							</Text>
							<Text fontSize={'14px'} lineHeight={'24px'} color={'#737283'}>
								2. You can only cancel up to 3 orders daily. Otherwise, your
								account will be suspended, and you cannot place any more orders
								on that day.
							</Text>
						</Flex>
						<Flex
							w={'100%'}
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Box w={'calc(35% - 10px)'}>
								<PreviousButton onClick={onClose}>Cancel</PreviousButton>
							</Box>
							<Box w={'calc(65% - 10px)'}>
								<NextButton onClick={onCancelPayment}>
									Confirm payment
								</NextButton>
							</Box>
						</Flex>
					</Flex>
				</StyledModalBody>
			</StyledModalContent>
		</Modal>
	);
}
