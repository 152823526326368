import React from 'react';

export default function ArrowStepLongIcon() {
	return (
		<svg
			width={229}
			height={5}
			viewBox="0 0 229 5"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.4 2.5C0.4 3.38366 1.11634 4.1 2 4.1C2.88366 4.1 3.6 3.38366 3.6 2.5C3.6 1.61634 2.88366 0.9 2 0.9C1.11634 0.9 0.4 1.61634 0.4 2.5ZM229 2.5L226 0.767949V4.23205L229 2.5ZM2 2.8H226.3V2.2H2V2.8Z"
				fill="url(#paint0_linear_780_3918)"
				fillOpacity="0.6"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_780_3918"
					x1={-3}
					y1="2.51714"
					x2="-2.87649"
					y2="8.91558"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" stopOpacity="0.58" />
					<stop offset={1} stopColor="white" stopOpacity="0.12" />
				</linearGradient>
			</defs>
		</svg>
	);
}
