import React from 'react';

export default function P2PIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.5547 7.10938C10.0007 7.10938 7.10938 10.0007 7.10938 13.5547C7.10938 17.1086 10.0007 20 13.5547 20C17.1086 20 20 17.1086 20 13.5547C20 10.0007 17.1086 7.10938 13.5547 7.10938ZM13.5547 12.9688C14.524 12.9688 15.3125 13.7573 15.3125 14.7266C15.3125 15.4895 14.8212 16.1337 14.1406 16.3764V17.6562H12.9688V16.3764C12.2882 16.1337 11.7969 15.4895 11.7969 14.7266H12.9688C12.9688 15.0498 13.2314 15.3125 13.5547 15.3125C13.878 15.3125 14.1406 15.0498 14.1406 14.7266C14.1406 14.4033 13.878 14.1406 13.5547 14.1406C12.5854 14.1406 11.7969 13.3521 11.7969 12.3828C11.7969 11.6199 12.2882 10.9756 12.9688 10.7329V9.45312H14.1406V10.7329C14.8212 10.9756 15.3125 11.6199 15.3125 12.3828H14.1406C14.1406 12.0595 13.878 11.7969 13.5547 11.7969C13.2314 11.7969 12.9688 12.0595 12.9688 12.3828C12.9688 12.7061 13.2314 12.9688 13.5547 12.9688Z"
				fill="#737283"
			/>
			<path
				d="M6.52344 7.10938C10.1375 7.10938 12.9688 5.565 12.9688 3.59375C12.9688 1.6225 10.1375 0 6.52344 0C2.90938 0 0 1.6225 0 3.59375C0 5.565 2.90938 7.10938 6.52344 7.10938Z"
				fill="#737283"
			/>
			<path
				d="M0 13.186V14.1408C0 16.112 2.90938 17.6564 6.52344 17.6564C6.72844 17.6564 6.92707 17.6375 7.12891 17.6276C6.67945 16.921 6.34426 16.1368 6.14687 15.2985C3.5334 15.2264 1.26414 14.4233 0 13.186Z"
				fill="#737283"
			/>
			<path
				d="M5.96566 14.1109C5.95223 13.9266 5.9375 13.7425 5.9375 13.5548C5.9375 12.944 6.01754 12.3528 6.15402 11.7832C3.5373 11.7123 1.26527 10.9088 0 9.67041V10.6251C0 12.4918 2.62742 13.9588 5.96566 14.1109Z"
				fill="#737283"
			/>
			<path
				d="M6.52344 10.6251C6.52406 10.6251 6.52457 10.6251 6.52523 10.6251C6.91168 9.70146 7.47344 8.86889 8.17105 8.17131C7.64188 8.23818 7.095 8.28139 6.52344 8.28139C3.74152 8.28139 1.32523 7.45182 0 6.15479V7.10951C0 9.08076 2.90938 10.6251 6.52344 10.6251Z"
				fill="#737283"
			/>
		</svg>
	);
}
