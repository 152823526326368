import { chakra, Text } from '@chakra-ui/react';

export const PrimaryText = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '14px',
			lg: '16px',
		},
		color: '#ffffff',
		lineHeight: '16px',
	},
});

export const SecondaryText = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '12px',
			lg: '14px',
		},
		color: '#737283',
		lineHeight: '14px',
	},
});
