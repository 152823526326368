import axios from 'axios';
import { config } from '../config';

const BASE_URL = config.base_url;

export const APIS = {
	AUTH: '',
};

export default class BaseRequest {
	baseUrl: string;
	constructor(url = BASE_URL) {
		this.baseUrl = url;
		this.setAuth();
	}

	setAuth() {
		axios.interceptors.request.use(
			(config) => {
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error) => {
				const originalConfig = error.config;
				if (originalConfig.url !== BASE_URL + APIS.AUTH && error.response) {
					// Access Token was expired

					//get refresh token from storage
					const refreshToken = '';
					if (error.response.status === 401 && refreshToken) {
						try {
							//handle get jwt with refresh token
						} catch (_error) {
							return Promise.reject(_error);
						}
					} else if (error.response.status === 401) {
						///remove jwt
					}
				}
				return Promise.reject(error);
			}
		);
	}

	async get(path = '', params = {}): Promise<any> {
		try {
			return await axios.get(this.baseUrl + path, {
				params: params,
			});
		} catch (error) {
			return error;
		}
	}
	async post(path = '', data = {}): Promise<any> {
		try {
			return await axios.post(this.baseUrl + path, data);
		} catch (error) {
			return this._errorHandler(error);
		}
	}
	async put(path = '', data = {}): Promise<any> {
		try {
			return await axios.put(this.baseUrl + path, data);
		} catch (error) {
			return this._errorHandler(error);
		}
	}
	async delete(path = '', params = {}): Promise<any> {
		try {
			return await axios.delete(this.baseUrl + path, params);
		} catch (error) {
			return this._errorHandler(error);
		}
	}
	async patch(path = '', data = {}): Promise<any> {
		try {
			return await axios.patch(this.baseUrl + path, data);
		} catch (error) {
			return this._errorHandler(error);
		}
	}
	async _errorHandler(err: any) {
		throw err;
	}
}
