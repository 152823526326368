import SpotAccount from '../../components/Wallets/SpotAccount/SpotAccount';

export default function SpotWalletPage() {
	return (
		<div
		// style={{
		// 	paddingTop: '92px',
		// 	paddingLeft: '20px',
		// 	paddingRight: '20px',
		// 	paddingBottom: '20px',
		// }}
		>
			<SpotAccount />
		</div>
	);
}
