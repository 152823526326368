import React from 'react';

const CardPaymentIcon = () => {
	return (
		<svg
			width={32}
			height={32}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25.3829 7H6.61697C5.03316 7 3.74463 8.28853 3.74463 9.87234V22.1277C3.74463 23.7115 5.03316 25 6.61697 25H25.3829C26.9667 25 28.2553 23.7115 28.2553 22.1277V9.87234C28.2553 8.28853 26.9667 7 25.3829 7ZM26.3404 22.1277C26.3404 22.6556 25.9109 23.0851 25.3829 23.0851H6.61697C6.08903 23.0851 5.65952 22.6556 5.65952 22.1277V13.9894H26.3404V22.1277ZM26.3404 11.117H5.65952V9.87234C5.65952 9.3444 6.08903 8.91489 6.61697 8.91489H25.3829C25.9109 8.91489 26.3404 9.3444 26.3404 9.87234V11.117Z"
				fill="#737283"
			/>
			<path d="M19.6383 15.4255H7V17.3404H19.6383V15.4255Z" fill="#737283" />
		</svg>
	);
};

export default CardPaymentIcon;
