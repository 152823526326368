import { config } from '../config';
import BaseRequest from './BaseRequest';

const baseReq = new BaseRequest(config.coingecko_url);

export const coingeckoService = {
	getMarkets: (params?: object) => {
		return baseReq.get('coins/markets', { ...params });
	},
};
