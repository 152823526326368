export const DEPOSIT_STEPS: Step[] = [
	{
		stepNumber: 1,
		title: 'Copy Address',
		description:
			'Choose the crypto and its network on this page, and copy the deposit address',
	},
	{
		stepNumber: 2,
		title: 'Initiate a deposit',
		description: 'Initiate a deposit on the deposit platform',
	},
	{
		stepNumber: 3,
		title: 'Network confirmation',
		description: 'Wait for the blockchain network to confirm your transfer',
	},
	{
		stepNumber: 4,
		title: 'Deposit successful',
		description:
			'After the network confirmation, We will credit the crypto for you',
	},
];
export const WITHDRAW_STEPS: Step[] = [
	{
		stepNumber: 1,
		title: 'Initiate a withdrawal',
		description: 'Start a withdrawal request',
	},
	{
		stepNumber: 2,
		title: 'Get address',
		description: 'Copy and paste the deposit address of the receiver',
	},
	{
		stepNumber: 3,
		title: 'Network confirmation',
		description: 'Wait for the blockchain network to confirm your transfer',
	},
	{
		stepNumber: 4,
		title: 'Withdraw successful',
		description: "Deposit successfully sent to receiver's address",
	},
];
