import { Flex } from '@chakra-ui/react';
import React from 'react';
import Profile from '../../components/Account/AccountList/Profile';
import Settings from '../../components/Account/AccountList/Settings';

export default function AccountList() {
	return (
		<Flex direction={{ base: 'column' }} gap={{ base: '40px' }}>
			<Profile />
			<Settings />
		</Flex>
	);
}
