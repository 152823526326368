export default function WarningIcon(props: any) {
	return (
		<svg
			{...props}
			width={16}
			height={22}
			viewBox="0 0 16 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.2057 12.6758L10.4694 4.47244C10.2191 4.03903 9.85919 3.67912 9.42577 3.42888C8.99234 3.17864 8.50069 3.04689 8.00022 3.04688C7.49974 3.04686 7.00808 3.17857 6.57464 3.42877C6.14119 3.67898 5.78124 4.03886 5.53096 4.47225L0.794427 12.6759C0.544254 13.1094 0.412567 13.6011 0.412598 14.1016C0.412628 14.602 0.544374 15.0937 0.794599 15.5271C1.04482 15.9606 1.40471 16.3205 1.83811 16.5708C2.2715 16.8211 2.76314 16.9529 3.26361 16.953H12.7366C13.2371 16.9529 13.7287 16.8211 14.1621 16.5708C14.5955 16.3205 14.9554 15.9605 15.2056 15.5271C15.4559 15.0936 15.5876 14.602 15.5876 14.1015C15.5876 13.601 15.4559 13.1093 15.2057 12.6758Z"
				fill="#FE646F"
			/>
			<path
				d="M15.2056 12.676L10.4692 4.47262C10.2215 4.037 9.8621 3.67528 9.42809 3.4247C8.99409 3.17411 8.50114 3.04372 8 3.04693C7.88512 3.04702 7.77034 3.05382 7.65625 3.06731C8.09607 3.11784 8.51777 3.27143 8.88707 3.51559C9.25637 3.75976 9.56286 4.0876 9.78162 4.4725L14.5181 12.676C14.7683 13.1095 14.9 13.6012 14.9 14.1017C14.9 14.6021 14.7683 15.0938 14.518 15.5273C14.2678 15.9607 13.9079 16.3207 13.4745 16.571C13.0411 16.8213 12.5495 16.9531 12.049 16.9532H12.7365C13.237 16.9531 13.7286 16.8213 14.162 16.571C14.5954 16.3207 14.9553 15.9607 15.2055 15.5273C15.4558 15.0938 15.5875 14.6021 15.5875 14.1017C15.5875 13.6012 15.4558 13.1095 15.2056 12.676Z"
				fill="#FD4755"
			/>
			<path
				d="M9.41198 5.08254L11.7798 9.18476L14.1485 13.2861C14.2916 13.5339 14.3669 13.8151 14.3669 14.1012C14.3668 14.3874 14.2915 14.6685 14.1484 14.9163C14.0053 15.164 13.7994 15.3698 13.5516 15.5128C13.3037 15.6558 13.0226 15.7311 12.7365 15.731H3.26348C2.97742 15.7309 2.69641 15.6555 2.44869 15.5124C2.20098 15.3694 1.99529 15.1636 1.85229 14.9158C1.70929 14.6681 1.63402 14.387 1.63403 14.101C1.63405 13.8149 1.70936 13.5339 1.85239 13.2861L4.22017 9.18476L6.58892 5.08254C6.73198 4.83476 6.93774 4.629 7.18552 4.48595C7.4333 4.34289 7.71437 4.26758 8.00048 4.26758C8.2866 4.26758 8.56767 4.34289 8.81545 4.48595C9.06323 4.629 9.26899 4.83476 9.41205 5.08254H9.41198Z"
				fill="url(#paint0_linear_507_1906)"
			/>
			<path
				d="M8.00008 7.6582C8.42971 7.6582 8.79908 8.00986 8.78049 8.43861L8.6609 11.1687C8.64318 11.5319 8.36402 11.8295 8.00008 11.8295C7.6369 11.8295 7.35521 11.5319 7.33927 11.1687L7.21968 8.43861C7.20093 8.00986 7.57133 7.6582 8.00008 7.6582ZM8.00008 12.5701C7.86921 12.5701 7.74127 12.6089 7.63245 12.6816C7.52363 12.7543 7.43881 12.8577 7.38873 12.9786C7.33865 13.0995 7.32555 13.2325 7.35108 13.3609C7.37662 13.4893 7.43964 13.6072 7.53219 13.6997C7.62473 13.7923 7.74264 13.8553 7.87101 13.8808C7.99937 13.9063 8.13242 13.8932 8.25333 13.8431C8.37425 13.793 8.47759 13.7082 8.5503 13.5994C8.623 13.4906 8.66181 13.3626 8.6618 13.2318C8.66178 13.0563 8.59206 12.888 8.46797 12.7639C8.34388 12.6398 8.17557 12.5701 8.00008 12.5701Z"
				fill="#FE646F"
			/>
			<path
				d="M14.1484 13.286L11.7797 9.18462L9.41195 5.08241C9.21647 4.73404 8.89726 4.4718 8.51758 4.34766C8.66195 4.50493 8.78853 4.67765 8.89502 4.86269L13.6314 13.0661C13.8204 13.3887 13.9432 13.7456 13.9929 14.1162C14.0425 14.4868 14.0178 14.8635 13.9203 15.2245C14.0077 15.1305 14.0842 15.027 14.1485 14.9159C14.2954 14.6695 14.373 14.3879 14.373 14.1009C14.373 13.814 14.2954 13.5324 14.1484 13.286Z"
				fill="#EFCE67"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_507_1906"
					x1="1.63403"
					y1="15.731"
					x2="14.3669"
					y2="15.731"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
			</defs>
		</svg>
	);
}
