import styled from '@emotion/styled';

const TextGradientWrapper = styled.span`
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
`;
interface TextGradientProps extends React.HTMLAttributes<HTMLSpanElement> {
	children: React.ReactNode;
}
const TextGradient: React.FC<TextGradientProps> = ({
	children,
	...props
}: TextGradientProps) => {
	return <TextGradientWrapper {...props}>{children}</TextGradientWrapper>;
};
export default TextGradient;
