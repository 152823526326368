import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import TableUI from '../../UI/TableUI';

const columns = [
	{
		key: 'user',
		title: 'User',
	},
	{
		key: 'credit_value',
		title: 'Credit Value',
		render: (value: string) => <Box color="#737283">{value}</Box>,
	},
	{
		key: 'trade_history',
		title: 'Trade History',
	},
	{
		key: 'trades',
		title: 'Trades',
	},
	{
		key: 'operation',
		title: 'Operation',
	},
];

const dataTable: { [key: string]: any }[] = [
	{
		user: 'ben*****@gmail.com',
		credit_value: '3205**********5017',
		trade_history: '29,365.62',
		trades: '29,365.62',
		operation: 'Buy',
	},
	{
		user: 'ben*****@gmail.com',
		credit_value: '3205**********5017',
		trade_history: '29,365.62',
		trades: '29,365.62',
		operation: 'Buy',
	},
	{
		user: 'ben*****@gmail.com',
		credit_value: '3205**********5017',
		trade_history: '29,365.62',
		trades: '29,365.62',
		operation: 'Buy',
	},
	{
		user: 'ben*****@gmail.com',
		credit_value: '3205**********5017',
		trade_history: '29,365.62',
		trades: '29,365.62',
		operation: 'Buy',
	},
];

const TableBlacklist = () => {
	return (
		<>
			<Box display={{ base: 'none', md: 'block' }}>
				<TableUI isBorder={true} columns={columns} dataTable={dataTable} />
			</Box>

			<Box display={{ base: 'block', md: 'none' }}>
				{dataTable.map((data, index) => {
					return (
						<Flex
							justifyContent={'space-between'}
							key={index}
							borderTop={'1px solid #FFFFFF1E'}
							p={'20px'}
						>
							<Box>
								{columns.slice(0, 3).map((col) => (
									<Flex key={col.key}>
										<Box w={'76px'} color={'#737283'} fontSize={'12px'}>
											{col.title}:{' '}
										</Box>
										<Box fontSize={'14px'}>{data[col.key]}</Box>
									</Flex>
								))}
							</Box>
							<Box>
								{columns.slice(3, columns.length).map((col) => (
									<Flex key={col.key}>
										<Box w={'60px'} color={'#737283'} fontSize={'12px'}>
											{col.title}:{' '}
										</Box>
										<Box fontSize={'14px'}>
											{data[col.key] === true ? (
												<Text color={'#21FF0E'}>Successful</Text>
											) : data[col.key] === false ? (
												<Text color={'#FF0E39'}>Failed</Text>
											) : (
												data[col.key]
											)}
										</Box>
									</Flex>
								))}
							</Box>
						</Flex>
					);
				})}
			</Box>
		</>
	);
};

export default TableBlacklist;
