import { Flex, Image } from '@chakra-ui/react';
import React from 'react';

export default function Submitted() {
	return (
		<Flex direction={'column'} alignItems={'center'}>
			<Image
				src={'/images/account-list/id-verification-submitted.svg'}
				alt="id-verification-submitted"
			/>
		</Flex>
	);
}
