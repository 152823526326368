import { Text } from '@chakra-ui/react';
import React from 'react';
import TableBlacklist from '../../components/Account/Blacklist/TableBlacklist';
import LineBox from '../../components/UI/LineBox';

const BlacklistPage = () => {
	return (
		<div>
			<Text fontSize={'20px'} my={'30px'} fontWeight={'500'}>
				Blacklist
			</Text>
			<LineBox />
			<TableBlacklist />
		</div>
	);
};

export default BlacklistPage;
