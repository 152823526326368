import React from 'react';

export default function P2PSettingsIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 15.6C0 16.2365 0.263392 16.847 0.732233 17.2971C1.20107 17.7471 1.83696 18 2.5 18H17.5C18.163 18 18.7989 17.7471 19.2678 17.2971C19.7366 16.847 20 16.2365 20 15.6V8H0V15.6ZM2.94643 12.3429C2.94643 12.0019 3.08753 11.6748 3.3387 11.4337C3.58986 11.1926 3.93051 11.0571 4.28571 11.0571H6.42857C6.78377 11.0571 7.12442 11.1926 7.37559 11.4337C7.62675 11.6748 7.76786 12.0019 7.76786 12.3429V13.2C7.76786 13.541 7.62675 13.868 7.37559 14.1091C7.12442 14.3503 6.78377 14.4857 6.42857 14.4857H4.28571C3.93051 14.4857 3.58986 14.3503 3.3387 14.1091C3.08753 13.868 2.94643 13.541 2.94643 13.2V12.3429Z"
				fill="#737283"
			/>
			<path
				d="M17.5 2H2.5C1.83696 2 1.20107 2.21585 0.732233 2.60007C0.263392 2.98429 0 3.50541 0 4.04878V6H20V4.04878C20 3.50541 19.7366 2.98429 19.2678 2.60007C18.7989 2.21585 18.163 2 17.5 2Z"
				fill="#737283"
			/>
		</svg>
	);
}
