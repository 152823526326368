import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import Body from '../../components/Account/IDVerification/Body';
import Container from '../../layouts/Container';

export default function IdVerificationPage() {
	return (
		<Container>
			<Box py={{ base: '18px', lg: '0px' }}>
				<Box>
					<Text fontSize={{ base: '20px' }} fontWeight={500}>
						ID Verification
					</Text>
				</Box>

				<Box
					h={'1px'}
					w={'100%'}
					background={
						'linear-gradient(100.78deg, rgba(255, 255, 255, 0.116) 0.27%, rgba(255, 255, 255, 0.024) 102.68%)'
					}
					my={{ base: '20px' }}
				/>

				<Box>
					<Body />
				</Box>
			</Box>
		</Container>
	);
}
