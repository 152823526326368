import React from 'react';
import Container from '../layouts/Container';
import { Box, Flex, Text, chakra } from '@chakra-ui/react';

const Title = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '32px',
		},
		fontWeight: 500,
	},
});

const Content = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '16px',
		},
		color: '#737283',
	},
});

export default function RishDisclaimer() {
	return (
		<Container>
			<Flex direction={'column'} gap={'20px'} py={{ base: '20px' }}>
				<Title>Risk Disclaimer</Title>

				<Box>
					<Content>2022-01-20</Content>
					<Content>11:45:35</Content>
				</Box>

				<Flex direction={'column'} gap={'20px'}>
					<Content fontWeight={500}>Article 1 Compensation for Damages</Content>

					<Content>
						{`The company shall not be liable for any accidents arising out of
						transactions that are outside of the trading rules stipulated in
						these terms and conditions and shall not be liable for any dispute
						arising out of the negligence of the seller or buyer. The damage or
						loss caused by the company's affiliates shall be in accordance with
						the terms of the affiliates, and the dispute between the affiliates
						and the customers shall be settled in principle.`}
					</Content>

					<Content fontWeight={500}>Article 2 Disclaimer</Content>

					<Content>
						The Company shall not be liable for any of the following:
						<br />
						A) In case of force majeure such as exhibition, natural disasters or
						national emergency
					</Content>
					<Content>
						B) In the event of damages caused by intention or negligence of the
						user
					</Content>
					<Content>
						C) In the case of a telecommunication service failure provided by
						other telecommunication carriers
					</Content>

					<Box>
						<Content>
							{`The company shall not be liable for any disruption in the use of
							the service due to the reasons for the user's fault or mistake.`}
						</Content>
						<Content>
							The company shall not be liable for the contents of the materials
							posted or transmitted by users
						</Content>
						<Content>
							The company shall not be liable for any defects in the delivery of
							crypto-currency due to defective services of the crypto-currency
							issuance management system or telecommunication service provider
							or due to the periodic server inspection time
						</Content>
						<Content>
							The contents of the crypto-currency registered in our site
							Exchange is registered by each customer and the company is not
							responsible for the contents of the registration
						</Content>
					</Box>
				</Flex>
			</Flex>
		</Container>
	);
}
