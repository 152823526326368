import { Asset } from 'metaverse-js/lib/proto/model/asset';

export const mapAssetsToOptions = (asset: Asset[]): Option[] => {
	if (!asset) return [];

	const mappedSupportedAssets = asset.map((item: Asset) => ({
		value: item.symbol || '',
		key: item.symbol || '',
		icon: item.symbol?.toLowerCase() || '',
	}));
	return mappedSupportedAssets;
};

export const mapChainsToOptions = (
	chains: (string | undefined)[]
): Option[] => {
	if (!chains) return [];
	const filteredChains = chains.filter((item) => item !== undefined);
	const mappedSupportedChains = (filteredChains as string[]).map(
		(item: string) => ({
			key: item,
			value: item,
		})
	);
	return mappedSupportedChains;
};
