export default function StepArrow(props: any) {
	return (
		<svg
			width={104}
			height={33}
			viewBox="0 0 104 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M0.833333 16.5C0.833333 17.9728 2.02724 19.1667 3.5 19.1667C4.97276 19.1667 6.16667 17.9728 6.16667 16.5C6.16667 15.0272 4.97276 13.8333 3.5 13.8333C2.02724 13.8333 0.833333 15.0272 0.833333 16.5ZM103.5 16.5L98.5 13.6132V19.3868L103.5 16.5ZM3.5 17H99V16H3.5V17Z"
				fill="url(#paint0_linear_486_11257)"
				fillOpacity="0.6"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_486_11257"
					x1="3.5"
					y1="16.5171"
					x2="3.68506"
					y2="22.9126"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" stopOpacity="0.58" />
					<stop offset={1} stopColor="white" stopOpacity="0.12" />
				</linearGradient>
			</defs>
		</svg>
	);
}
