import React from 'react';
import Body from '../../components/P2PAds/Body';

export default function P2PAdsPage() {
	return (
		<div>
			<Body />
		</div>
	);
}
